import DS from "ember-data";
import {isEqual} from "@ember/utils";
import {computed} from "@ember/object";

const {attr} = DS;

export default DS.Model.extend({
    positionType: attr("string"),

    type: attr("string"),
    strike: attr("number"),
    price: attr("number"),
    quantity: attr("number"),
    long: attr("boolean"),
    timestamp: attr("date-input"),
    broker: attr("string"),
    instrument_id: attr("string"),

    month_id: DS.belongsTo("month"),
    month: computed.alias("month_id"),
    product_id: DS.attr("string"),
    user_id: DS.attr("number"),
    userId: computed.alias("user_id"),

    shouldIncludeInPhysical: computed("product.globexSymbol", "positionType", "month.vaultType", function () {
        const isAgropurSymbol = ["BASIS", "DC"].indexOf(this.get("product.globexSymbol")) >= 0;
        const isPhysical = isEqual(this.get("positionType"), "PHYSICAL");
        return isEqual(this.get("month.vaultType"), "AGROPUR") ? isAgropurSymbol && isPhysical : isPhysical;
    }),

    priceFormatting: computed("product.globexSymbol", function () {
        let format = null;
        switch (this.get("product.globexSymbol")) {
            case "DC": format = "$0.00";
                break;
            case "CSC": format = "$0.0000";
                break;
            default: format = "$0.00";
        }
        return format;
    }),

    instrument: computed("month.{content,instruments}", "product.globexSymbol", function () {
        return this.get("month.content") && this.get("month.content").getInstrumentBySymbol ? this.get("month.content").getInstrumentBySymbol(this.get("product.globexSymbol")) : null;
    }),

    historicalPrice: computed("product.globexSymbol", "month.historicalPrice.{content,prices}", function () {
        return this.get("month.historicalPrice.content") && this.get("month.historicalPrice.content").bySymbol ? this.get("month.historicalPrice.content").bySymbol(this.get("product.globexSymbol")) : 0;
    }),

    currentPrice: computed("month.{classThreeMilkPrice,cashSettledCheesePrice}", "product.globexSymbol", function () {
        let price;
        switch (this.get("product.globexSymbol")) {
            case "DC": price = this.get("month.classThreeMilkPrice");
                break;
            case "CSC": price = this.get("month.cashSettledCheesePrice");
                break;
            default: price = this.get("month.cashSettledCheesePrice");
        }
        return price;
    }),

    plInDollars: computed("quantity", "long", "price", "type", "strike", "product_id", "currentPrice", "agropurBasisPlInDollars", function () {
        if (this.get("product_id") === "BASIS") {
            return this.get("agropurBasisPlInDollars");
        }

        let price = this.get("currentPrice");

        const quantity = this.get("quantity");
        let result = 0;

        if (this.get("type") === "FORWARD") {
            result = quantity * (this.get("price") - price) * (this.get("long") ? -1 : 1);
        } else if (this.get("type") === "PUT" || this.get("type") === "CALL") {
            const strikePriceDiff = this.get("strike") - price;
            if (this.get("type") === "PUT" && strikePriceDiff > 0) {
                result += quantity * strikePriceDiff * (this.get("long") ? 1 : -1);
            } else if (this.get("type") === "CALL" && strikePriceDiff < 0) {
                result += quantity * strikePriceDiff * (this.get("long") ? -1 : 1);
            }

            const _price = this.get("price") * quantity;

            if (this.get("long")) {
                result -= _price;
            } else {
                result += _price;
            }
        }

        return result;
    }),

    agropurBasisPlInDollars: computed("price", "month.agropurBasisPrice", "quantity", function () {
        return((this.get("price") - this.get("month.agropurBasisPrice")) * (this.get("quantity") / 100) || 0);
    }),

    premiumInTicks: computed("quantity", "long", "price", "type", "strike", "product.{globexSymbol,contractSize}", "instrument.{type}", function () {
        const quantity = this.get("quantity") / 100;
        let total = 0;

        if (quantity > 0 && this.get("instrumentType") === "Option" && this.get("long")) {
            total += this.get("price") * (quantity / this.get("product.contractSize"));
        }

        if (quantity > 0 && this.get("instrumentType") === "Option" && !this.get("long")) {
            total -= this.get("price") * (quantity / this.get("product.contractSize"));
        }

        return total;
    }),

    premiumInDollars: computed("premiumInTicks", "product.{tickSize,tickValue}", function () {
        return(this.get("premiumInTicks") / (this.get("product.tickSize") * this.get("product.tickValue")));
    })
});
