import Route from "@ember/routing/route";
import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import { hash } from "rsvp";
import moment from "moment";
import { inject as service } from "@ember/service";

export default Route.extend(AuthenticatedRouteMixin, {
	marketDataPolling: service("market-data"),
	queryParams: {
		period: {
			refreshModel: true
		},
		dairy_id: {
			refreshModel: true
		},
		organization_id: {
			refreshModel: true
		},
		display_mode: {
			refreshModel: true
		}
	},
	model(params) {
		let periodDate = moment(params.period, "YYYYMM");
		let { fullName } = this.get("session.data.authenticated");
		return hash({
			dairies: this.get("store").findAll("dairy"),
			dairy: params.dairy_id
				? this.get("store").findRecord("dairy", params.dairy_id)
				: null,
			userOrganizationConfigs: this.get("store").query(
				"user-organization-config",
				{ organization_id: params.organization_id }
			),
			organizations: this.get("store").findAll("organization"),
			organization: params.organization_id
				? this.get("store").findRecord("organization", params.organization_id)
				: null,
			periodDate,
			fullName,
			displayMode: params.display_mode,
			currentUserId: this.get("session.data.authenticated.userId"),
			classThreeFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "DC"
			}),
			classFourFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "GDK"
			}),
			dryWheyFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "DY"
			}),
			cashSettledCheeseFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "CSC"
			}),
			nonfatDryMilkFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "GNF"
			}),
			cashSettledButterFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "CB"
			}),
			soybeanMealFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "ZM"
			}),
			cornFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "ZC"
			}),
			soybeanFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "ZS"
			}),
			wheatFutures: this.store.query("instrument", {
				instrument_type: "future",
				product_group_symbol: "ZW"
			}),
			basisProduct: this.get("store").query("vault-product", { symbols: "BASIS" }),
			basisInstruments: this.get("store").findAll("vault-instrument"),
			historicalPrices: this.get("store").query("historical-price", {
				from: moment(periodDate)
					.subtract(1, "month")
					.format("YYYY-MM-DD"),
				to: moment(periodDate)
					.add(2, "years")
					.format("YYYY-MM-DD")
			})
		});
	},
	afterModel(model) {
		this._super(...arguments);

		model.organizations = model.organizations.sortBy("name");

		if (!model.organization || !model.dairy) {
			model.organization = model.organizations.firstObject;

			const initialDairy = model.dairies.find(e => {
				return e.get("organizationId.id") == model.organization.id;
			});

			model.dairy = initialDairy;
		}

		if (model.organization && model.organization.get("showAgropurBasis")) {
			let basisSymbols = model.basisInstruments.map(i => {
				return i.symbol;
			});
			model.basisMarketData = this.get("store").query("vault-market-datum", {
				sides: ["Last", "Close"],
				instrument_symbols: basisSymbols
			});
			model.basisInstruments = model.basisInstruments.sortBy("expiration");
		}

		const currentUserId = this.get("session.data.authenticated.userId");
		let userOrganizationConfig = model.userOrganizationConfigs.find(c => {
			return c.userId.get("id") == currentUserId;
		});

		const orgPermissions = userOrganizationConfig.get("permissions");

		model.isOrgAdmin = orgPermissions.includes(300);

		this.get("marketDataPolling").start();

		this.get("marketDataPolling").subscribeTo(model.classThreeFutures);
	},
	setupController(controller, model) {
		this._super(...arguments);

		controller.set("organization_id", model.organization.id);
		controller.set("dairy_id", model.dairy.id);

		// Temporary: Due to issue with server side permissions.
		if (model.dairy) {
			model.manualPositions = this.get("store").query("manual-position", {
				dairy_id: model.dairy.id
			});
		}

		if (
			!model.dairy.isClickwrapAccepted &&
			model.dairy.userId == model.currentUserId
		) {
			controller.set("showClickWrap", true);
		}
	},
	actions: {
		updatePeriod(num, value) {
			this.controller.set(
				"period",
				moment(this.get("context").periodDate)
					.add(num, value)
					.format("YYYYMM")
			);
		},
		updateDisplayMode(mode) {
			this.controller.set("display_mode", mode);
		},
		switchDairy(dairy) {
			this.controller.set("dairy_id", dairy.id);
		},
		toggleInfoModal() {
			this.controller.toggleProperty("showInfoModal");
		},
		showClickWrap() {
			this.controller.set("showClickWrap", true);
		}
	}
});
