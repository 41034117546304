import DS from "ember-data";
import ENV from "agropur-client/config/environment";
import DateUtils from "agropur-client/utils/date-utils";
import { computed } from "@ember/object";
import { isEqual } from "@ember/utils";

const { toUtc } = DateUtils;

const { attr, Model, belongsTo } = DS;
const { currentRollbackMonth } = DateUtils;

export default Model.extend({
	date: attr("utcdate"),
	cowsInMilk: attr("number", { defaultValue: 0 }),
	productionType: computed.alias("dairy.productionType"),
	production: attr("number", { defaultValue: 0 }),
	actualProduction: attr("number", { defaultValue: 0 }),

	historicalPrice: belongsTo("historical-price", { inverse: null }),
	previous: belongsTo("month", { inverse: null }),

	dairy: belongsTo("dairy"),

	classThreeMilkInstrument: null,
	cashSettledCheeseInstrument: null,
	agropurBasisInstrument: null,

	totalPoundsPerContract: 200000,

	classThreeMilkPercentageHedgedLimit: ENV.APP.HEDGE_LIMITS.classThreeMilk,
	agropurBasisPercentageHedgedLimit: ENV.APP.HEDGE_LIMITS.agropurBasis,

	cashSettledCheeseForwardPriceHedged: computed(
		"cashSettledCheeseForwardPositions",
		"cashSettledCheeseForwardPoundsHedged",
		function() {
			let price = 0;
			this.cashSettledCheeseForwardPositions.forEach(p => {
				price +=
					p.get("price") *
					(p.get("quantity") / this.cashSettledCheeseForwardPoundsHedged);
			});
			return price;
		}
	),

	classThreeMilkForwardPriceHedged: computed(
		"classThreeMilkForwardPositions",
		"classThreeMilkForwardPoundsHedged",
		function() {
			let price = 0;
			this.classThreeMilkForwardPositions.forEach(p => {
				price +=
					p.get("price") *
					(p.get("quantity") / this.classThreeMilkForwardPoundsHedged);
			});
			return price;
		}
	),

	cashSettledCheesePutOptionPriceHedged: computed(
		"cashSettledCheesePutOptionPositions",
		"cashSettledCheesePutOptionPoundsHedged",
		function() {
			let price = 0;
			this.cashSettledCheesePutOptionPositions.forEach(p => {
				price +=
					(p.get("strike") - p.get("price")) *
					(p.get("quantity") / this.cashSettledCheesePutOptionPoundsHedged);
			});
			return price;
		}
	),

	classThreeMilkPutOptionPriceHedged: computed(
		"classThreeMilkPutOptionPositions",
		"classThreeMilkPutOptionPoundsHedged",
		function() {
			let price = 0;
			this.classThreeMilkPutOptionPositions.forEach(p => {
				price +=
					(p.get("strike") - p.get("price")) *
					(p.get("quantity") / this.classThreeMilkPutOptionPoundsHedged);
			});
			return price;
		}
	),

	displayedcashSettledCheesePutOptionPriceHedged: computed(
		"cashSettledCheesePutOptionPositions",
		"cashSettledCheesePutOptionPoundsHedged",
		function() {
			let price = 0;
			this.cashSettledCheesePutOptionPositions.forEach(p => {
				price +=
					p.get("strike") *
					(p.get("quantity") / this.cashSettledCheesePutOptionPoundsHedged);
			});
			return price;
		}
	),

	displayedClassThreeMilkPutOptionPriceHedged: computed(
		"classThreeMilkPutOptionPositions",
		"classThreeMilkPutOptionPoundsHedged",
		function() {
			let price = 0;
			this.classThreeMilkPutOptionPositions.forEach(p => {
				price +=
					p.get("strike") *
					(p.get("quantity") / this.classThreeMilkPutOptionPoundsHedged);
			});
			return price;
		}
	),

	cashSettledCheeseCallOptionPriceHedged: computed(
		"cashSettledCheeseCallOptionPositions",
		"cashSettledCheeseCallOptionPoundsHedged",
		function() {
			let price = 0;
			this.cashSettledCheeseCallOptionPositions.forEach(p => {
				price +=
					(p.get("strike") + p.get("price")) *
					(p.get("quantity") / this.cashSettledCheeseCallOptionPoundsHedged);
			});
			return price;
		}
	),

	classThreeMilkCallOptionPriceHedged: computed(
		"classThreeMilkCallOptionPositions",
		"classThreeMilkCallOptionPoundsHedged",
		function() {
			let price = 0;
			this.classThreeMilkCallOptionPositions.forEach(p => {
				price +=
					(p.get("strike") + p.get("price")) *
					(p.get("quantity") / this.classThreeMilkCallOptionPoundsHedged);
			});
			return price;
		}
	),

	cashSettledCheeseForwardPercentageHedged: computed(
		"cashSettledCheeseForwardPoundsHedged",
		"totalProduction",
		function() {
			return this.totalProduction > 0
				? this.cashSettledCheeseForwardPoundsHedged / this.totalProduction
				: Infinity;
		}
	),

	classThreeMilkForwardPercentageHedged: computed(
		"classThreeMilkForwardPoundsHedged",
		"totalProduction",
		function() {
			return this.totalProduction > 0
				? this.classThreeMilkForwardPoundsHedged / this.totalProduction
				: Infinity;
		}
	),

	cashSettledCheesePutOptionPercentageHedged: computed(
		"cashSettledCheesePutOptionPoundsHedged",
		"totalProduction",
		function() {
			return (
				this.cashSettledCheesePutOptionPoundsHedged / this.totalProduction || 0
			);
		}
	),

	classThreeMilkPutOptionPercentageHedged: computed(
		"classThreeMilkPutOptionPoundsHedged",
		"totalProduction",
		function() {
			return this.classThreeMilkPutOptionPoundsHedged / this.totalProduction || 0;
		}
	),

	cashSettledCheeseCallOptionPercentageHedged: computed(
		"cashSettledCheeseCallOptionPoundsHedged",
		"totalProduction",
		function() {
			return (
				this.cashSettledCheeseCallOptionPoundsHedged / this.totalProduction || 0
			);
		}
	),

	classThreeMilkCallOptionPercentageHedged: computed(
		"classThreeMilkCallOptionPoundsHedged",
		"totalProduction",
		function() {
			return this.classThreeMilkCallOptionPoundsHedged / this.totalProduction || 0;
		}
	),

	classThreePoundsHedged: computed.alias("classThreeMilkPoundsHedged"),

	cashSettledCheesePercentageHedged: computed(
		"cashSettledCheesePercentageHedgedOverTotalProduction",
		function() {
			return this.cashSettledCheesePercentageHedgedOverTotalProduction;
		}
	),

	classThreeMilkPercentageHedged: computed(
		"classThreeMilkPercentageHedgedOverTotalProduction",
		function() {
			return this.classThreeMilkPercentageHedgedOverTotalProduction;
		}
	),

	cashSettledCheesePercentageUnhedged: computed(
		"cashSettledCheesePercentageHedged",
		function() {
			return Math.max(0, 1 - this.cashSettledCheesePercentageHedged);
		}
	),

	classThreeMilkPercentageUnhedged: computed(
		"classThreeMilkPercentageHedged",
		function() {
			return Math.max(0, 1 - this.classThreeMilkPercentageHedged);
		}
	),

	offExchangeCashSettledCheesePL: computed(
		"cashSettledCheeseForwardPL",
		"cashSettledCheeseOptionPL",
		function() {
			return this.cashSettledCheeseForwardPL + this.cashSettledCheeseOptionPL;
		}
	),

	offExchangeClassThreeMilkPL: computed(
		"classThreeMilkForwardPL",
		"classThreeOptionPL",
		function() {
			return this.classThreeMilkForwardPL + this.classThreeOptionPL;
		}
	),

	cashSettledCheesePositions: computed.filterBy(
		"manualPositions",
		"product_id",
		"CSC"
	),

	classThreeMilkPositions: computed.filterBy(
		"manualPositions",
		"product_id",
		"DC"
	),

	cashSettledCheeseForwardPositions: computed.filterBy(
		"cashSettledCheesePositions",
		"type",
		"FORWARD"
	),

	classThreeMilkForwardPositions: computed.filterBy(
		"classThreeMilkPositions",
		"type",
		"FORWARD"
	),
	cashSettledCheesePutOptionPositions: computed.filterBy(
		"cashSettledCheesePositions",
		"type",
		"PUT"
	),
	classThreeMilkPutOptionPositions: computed.filterBy(
		"classThreeMilkPositions",
		"type",
		"PUT"
	),
	cashSettledCheeseCallOptionPositions: computed.filterBy(
		"cashSettledCheesePositions",
		"type",
		"CALL"
	),
	classThreeMilkCallOptionPositions: computed.filterBy(
		"classThreeMilkPositions",
		"type",
		"CALL"
	),

	cashSettledCheeseForwardPoundsHedged: computed(
		"cashSettledCheeseForwardPositions.@each.quantity",
		function() {
			return this.cashSettledCheeseForwardPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	classThreeMilkForwardPoundsHedged: computed(
		"classThreeMilkForwardPositions.@each.quantity",
		function() {
			return this.classThreeMilkForwardPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	cashSettledCheeseForwardCWTHedged: computed(
		"cashSettledCheeseForwardPoundsHedged",
		function() {
			return this.cashSettledCheeseForwardPoundsHedged / 100;
		}
	),

	classThreeMilkForwardCWTHedged: computed(
		"classThreeMilkForwardPoundsHedged",
		function() {
			return this.classThreeMilkForwardPoundsHedged / 100;
		}
	),

	cashSettledCheeseForwardPL: computed(
		"cashSettledCheeseForwardPositions.@each.plInDollars",
		function() {
			return this.cashSettledCheeseForwardPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	classThreeMilkForwardPL: computed(
		"classThreeMilkForwardPositions.@each.plInDollars",
		function() {
			return this.classThreeMilkForwardPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	cashSettledCheesePutOptionPoundsHedged: computed(
		"cashSettledCheesePutOptionPositions.@each.quantity",
		function() {
			return this.cashSettledCheesePutOptionPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	classThreeMilkPutOptionPoundsHedged: computed(
		"classThreeMilkPutOptionPositions.@each.quantity",
		function() {
			return this.classThreeMilkPutOptionPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	cashSettledCheesePutOptionCWTHedged: computed(
		"classThreeMilkPutOptionPoundsHedged",
		function() {
			return this.classThreeMilkPutOptionPoundsHedged / 100;
		}
	),

	classThreeMilkPutOptionCWTHedged: computed(
		"classThreeMilkPutOptionPoundsHedged",
		function() {
			return this.classThreeMilkPutOptionPoundsHedged / 100;
		}
	),

	cashSettledCheesePutOptionPL: computed(
		"cashSettledCheesePutOptionPositions.@each.plInDollars",
		function() {
			return this.cashSettledCheesePutOptionPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	classThreePutOptionPL: computed(
		"classThreeMilkPutOptionPositions.@each.plInDollars",
		function() {
			return this.classThreeMilkPutOptionPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	cashSettledCheeseCallOptionPoundsHedged: computed(
		"cashSettledCheeseCallOptionPositions.@each.quantity",
		function() {
			return this.cashSettledCheeseCallOptionPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	classThreeMilkCallOptionPoundsHedged: computed(
		"classThreeMilkCallOptionPositions.@each.quantity",
		function() {
			return this.classThreeMilkCallOptionPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	cashSettledCheeseCallOptionCWTHedged: computed(
		"cashSettledCheeseCallOptionPoundsHedged",
		function() {
			this.cashSettledCheeseCallOptionPoundsHedged / 100;
		}
	),

	classThreeMilkCallOptionCWTHedged: computed(
		"classThreeMilkCallOptionPoundsHedged",
		function() {
			this.classThreeMilkCallOptionPoundsHedged / 100;
		}
	),

	cashSettledCheeseOptionPoundsHedged: computed(
		"cashSettledCheesePutOptionPoundsHedged",
		"cashSettledCheeseCallOptionPoundsHedged",
		function() {
			return (
				this.cashSettledCheeseCallOptionPoundsHedged +
				this.cashSettledCheesePutOptionPoundsHedged
			);
		}
	),

	classThreeMilkOptionPoundsHedged: computed(
		"classThreeMilkPutOptionPoundsHedged",
		"classThreeMilkCallOptionPoundsHedged",
		function() {
			return (
				this.classThreeMilkCallOptionPoundsHedged +
				this.classThreeMilkPutOptionPoundsHedged
			);
		}
	),

	cashSettledCheeseOptionCWTHedged: computed(
		"cashSettledCheeseOptionPoundsHedged",
		function() {
			return this.cashSettledCheeseOptionPoundsHedged / 100;
		}
	),

	classThreeMilkOptionCWTHedged: computed(
		"classThreeMilkOptionPoundsHedged",
		function() {
			return this.classThreeMilkOptionPoundsHedged / 100;
		}
	),

	cashSettledCheeseCallOptionPL: computed(
		"cashSettledCheeseCallOptionPositions.@each.plInDollars",
		function() {
			return this.cashSettledCheeseCallOptionPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	classThreeCallOptionPL: computed(
		"classThreeMilkCallOptionPositions.@each.plInDollars",
		function() {
			return this.classThreeMilkCallOptionPositions.reduce((arr, p) => {
				return arr + p.plInDollars;
			}, 0);
		}
	),

	cashSettledCheeseOptionPL: computed(
		"cashSettledCheesePutOptionPL",
		"cashSettledCheeseCallOptionPL",
		function() {
			return (
				this.cashSettledCheeseCallOptionPL + this.cashSettledCheesePutOptionPL
			);
		}
	),

	classThreeOptionPL: computed(
		"classThreePutOptionPL",
		"classThreeCallOptionPL",
		function() {
			return this.classThreeCallOptionPL + this.classThreePutOptionPL;
		}
	),

	cashSettledCheesePoundsHedged: computed(
		"cashSettledCheeseForwardPoundsHedged",
		"cashSettledCheesePutOptionPoundsHedged",
		"cashSettledCheeseCallOptionPoundsHedged",
		function() {
			return (
				this.cashSettledCheeseForwardPoundsHedged +
				(this.cashSettledCheesePutOptionPoundsHedged >
				this.cashSettledCheeseCallOptionPoundsHedged
					? this.cashSettledCheesePutOptionPoundsHedged
					: this.cashSettledCheeseCallOptionPoundsHedged)
			);
		}
	),

	classThreeMilkPoundsHedged: computed(
		"classThreeMilkForwardPoundsHedged",
		"classThreeMilkPutOptionPoundsHedged",
		"classThreeMilkCallOptionPoundsHedged",
		function() {
			return (
				this.classThreeMilkForwardPoundsHedged +
				(this.classThreeMilkPutOptionPoundsHedged >
				this.classThreeMilkCallOptionPoundsHedged
					? this.classThreeMilkPutOptionPoundsHedged
					: this.classThreeMilkCallOptionPoundsHedged)
			);
		}
	),

	cashSettledCheeseCWTHedged: computed(
		"cashSettledCheesePoundsHedged",
		function() {
			return this.cashSettledCheesePoundsHedged / 100;
		}
	),

	classThreeMilkCWTHedged: computed("classThreeMilkPoundsHedged", function() {
		return this.classThreeMilkPoundsHedged / 100;
	}),

	agropurForwardPrice: computed(
		"classThreeMilkPrice",
		"agropurBasisPrice",
		function() {
			return this.classThreeMilkPrice + this.agropurBasisPrice;
		}
	),

	blendedMilkPrice: computed.alias("agropurForwardPrice"),

	basisMilkPositions: computed.filterBy(
		"manualPositions",
		"product_id",
		"BASIS"
	),
	basisMilkForwardPositions: computed.filterBy(
		"basisMilkPositions",
		"type",
		"FORWARD"
	),

	basisMilkForwardPoundsHedged: computed(
		"basisMilkForwardPositions.@each.quantity",
		function() {
			return this.basisMilkForwardPositions.reduce((arr, p) => {
				if (p && p.quantity) {
					return arr + parseInt(p.quantity);
				} else {
					return arr;
				}
			}, 0);
		}
	),

	agropurBasisPoundsHedged: computed("basisMilkForwardPoundsHedged", function() {
		return this.basisMilkForwardPoundsHedged;
	}),

	agropurBasisCWTHedged: computed("agropurBasisPoundsHedged", function() {
		return this.agropurBasisPoundsHedged / 100;
	}),

	agropurBasisPercentageHedged: computed(
		"agropurBasisPoundsHedged",
		"totalProduction",
		function() {
			return this.agropurBasisPoundsHedged / this.totalProduction || 0;
		}
	),

	agropurBasisStaticPoundsHedgeable: computed(
		"agropurBasisPercentageHedgedLimit",
		"totalProduction",
		function() {
			return (
				this.get("agropurBasisPercentageHedgedLimit") * this.get("totalProduction")
			);
		}
	),

	agropurBasisRemainingPoundsHedgeable: computed(
		"agropurBasisStaticPoundsHedgeable",
		"agropurBasisPoundsHedged",
		function() {
			return (
				this.get("agropurBasisStaticPoundsHedgeable") -
				this.get("agropurBasisPoundsHedged")
			);
		}
	),

	agropurClassThreeMilkStaticPoundsHedgeable: computed(
		"classThreeMilkPercentageHedgedLimit",
		"totalProduction",
		function() {
			return (
				this.get("classThreeMilkPercentageHedgedLimit") *
				this.get("totalProduction")
			);
		}
	),

	agropurClassThreeMilkRemainingPoundsHedgeable: computed(
		"agropurClassThreeMilkStaticPoundsHedgeable",
		"classThreeMilkPoundsHedged",
		function() {
			return (
				this.get("agropurClassThreeMilkStaticPoundsHedgeable") -
				this.get("classThreeMilkPoundsHedged")
			);
		}
	),

	agropurPLAdjustedClassThreePrice: computed(
		"classThreeMilkPrice",
		"offExchangeClassThreeMilkPL",
		"totalProductionCwt",
		function() {
			return (
				(this.classThreeMilkPrice * this.totalProductionCwt +
					this.offExchangeClassThreeMilkPL) /
					this.totalProductionCwt || 0
			);
		}
	),

	agropurPLAdjustedBasisPrice: computed(
		"agropurBasisPrice",
		"totalProduction",
		"agropurBasisPoundsHedged",
		"agropurBasisHedgePrice",
		function() {
			return (
				(this.agropurBasisPrice *
					(this.totalProduction - this.agropurBasisPoundsHedged) +
					this.agropurBasisHedgePrice * this.agropurBasisPoundsHedged) /
					this.totalProduction || 0
			);
		}
	),

	agropurPLAdjustedForwardPrice: computed(
		"agropurPLAdjustedClassThreePrice",
		"agropurPLAdjustedBasisPrice",
		function() {
			return this.classThreeMilkPrice + this.basisPrice;
		}
	),

	agropurBasisPercentageUnhedged: computed(
		"agropurBasisPercentageHedged",
		function() {
			return Math.max(0, 1 - this.agropurBasisPercentageHedged);
		}
	),

	agropurBasisHedgePrice: computed(
		"basisMilkPositions",
		"agropurBasisPoundsHedged",
		function() {
			let price = 0;
			this.basisMilkPositions.forEach(p => {
				price +=
					p.get("price") * (p.get("quantity") / this.agropurBasisPoundsHedged);
			});
			return price;
		}
	),

	agropurBasisNetPL: computed(
		"agropurBasisPoundsHedged",
		"agropurBasisHedgePrice",
		"agropurBasisPrice",
		function() {
			return this.agropurBasisPoundsHedged > 0
				? (this.agropurBasisHedgePrice - this.agropurBasisPrice) *
						(this.agropurBasisPoundsHedged / 100)
				: 0;
		}
	),

	totalAgropurBasisNetPL: computed.alias("agropurBasisNetPL"),

	cashSettledCheesePercentageHedgedOverTotalProduction: computed(
		"cashSettledCheesePoundsHedged",
		"totalProduction",
		function() {
			return this.cashSettledCheesePoundsHedged / this.totalProduction || 0;
		}
	),

	classThreeMilkPercentageHedgedOverTotalProduction: computed(
		"classThreeMilkPoundsHedged",
		"totalProduction",
		function() {
			return this.classThreeMilkPoundsHedged / this.totalProduction || 0;
		}
	),

	getPhysicalPositions(globexSymbol) {
		return this.get("physicalPositions").filter(p =>
			isEqual(p.get("product.globexSymbol"), globexSymbol)
		);
	},

	getPhysicalPositionsOptionPL(globexSymbol) {
		let total = 0;
		total += this.getPhysicalPositionsValue(globexSymbol, "CALL", "plInDollars");
		total += this.getPhysicalPositionsValue(globexSymbol, "PUT", "plInDollars");
		return total;
	},

	getPhysicalPositionsValue(globexSymbol, type, attrValue) {
		const fSum = (ttl, p) => ttl + p.get(attrValue);
		const filtered = this.getPhysicalPositions(globexSymbol);
		return filtered
			.filter(p => isEqual(p.get("type").toUpperCase(), type))
			.reduce(fSum, 0);
	},

	/* ============= MANUAL POSITIONS ============= */
	manualPositions: DS.hasMany("manual-position"),
	physicalPositions: computed.filterBy(
		"manualPositions",
		"shouldIncludeInPhysical",
		true
	),

	isPast: computed("date", function() {
		return toUtc(this.get("date")).isBefore(currentRollbackMonth(), "month");
	}),

	isFirstOfTheYear: computed("date", function() {
		// IS JANUARY ?
		return toUtc(this.get("date")).get("month") === 0;
	}),

	daysInMonth: computed("date", function() {
		return toUtc(this.get("date")).daysInMonth();
	}),

	/* ============= MILK PRODUCTION ============= */
	hasValidMilkProduction: computed(
		"productionType",
		"cowsInMilk",
		"production",
		function() {
			return this.get("productionType") === "POUNDS_COW_DAY"
				? this.get("cowsInMilk") > 0 && this.get("production") > 0
				: this.get("production") > 0;
		}
	),

	totalProduction: computed.alias("production"),

	totalProductionCwt: computed("totalProduction", function() {
		return this.get("totalProduction");
	}),

	totalMilkPercentageUnhedged: computed("totalMilkPercentageHedged", function() {
		return Math.max(0, 1 - this.get("totalMilkPercentageHedged"));
	}),

	/* ============= MILKCHECK ============= */

	milkCheck: computed.alias("blendedMilkPrice"),

	totalMilkCheck: computed(
		"cashSettledCheesePrice",
		"totalProduction",
		function() {
			return this.get("cashSettledCheesePrice") * this.get("totalProduction");
		}
	),

	totalDairyHedgePL: computed("offExchangeCashSettledCheesePL", function() {
		return this.get("offExchangeCashSettledCheesePL");
	}),

	totalDairyRevenue: computed("totalMilkCheck", "totalDairyHedgePL", function() {
		return this.get("totalMilkCheck") + this.get("totalDairyHedgePL");
	}),

	_getInstrumentPrice(instrument, type = "price") {
		return instrument ? instrument.get(type) : 0;
	},

	/* ============= FORMATTED PRICES ============= */

	classThreeMilkPrice: computed(
		"classThreeMilkInstrument.bestPrice",
		function() {
			return this.get("historicalPrice.classThree")
				? this.get("historicalPrice.classThree")
				: this.get("classThreeMilkInstrument.bestPrice") / 100;
			//return this._getHistoricalOrInstrumentPrice(...['price', [this.get('isPast'), this.get('classThreeMilkInstrument'), this.get('historicalPrice.classThree'), this.get('classThreeMilkInstrument.price')]]) || 0;
		}
	),

	agropurBasisPrice: computed(
		"agropurBasisInstrument",
		"agropurBasisInstrument.price",
		function() {
			return this.get("agropurBasisInstrument")
				? this.get("agropurBasisInstrument.price")
				: 0;
		}
	),

	cashSettledCheesePrice: computed(
		"cashSettledCheeseInstrument.bestPrice",
		function() {
			return this.get("historicalPrice.cashSettledCheese")
				? this.get("historicalPrice.cashSettledCheese")
				: this.get("cashSettledCheeseInstrument.bestPrice") / 1000;
		}
	)
});
