import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import Route from "@ember/routing/route";
import { hash } from "rsvp";
import moment from "moment";
import EmberObject from "@ember/object";

export default Route.extend(AuthenticatedRouteMixin, {
	model() {
		const {
			periodDate,
			dairy,
			displayMode,
			cashSettledCheeseFutures,
			currentUserId,
			isOrgAdmin
		} = this.modelFor("authenticated");

		return hash({
			periodDate,
			displayMode,
			cashSettledCheeseFutures,
			currentUserId,
			isOrgAdmin,
			months: this.get("store").query("month", {
				start_date: periodDate.format("YYYY-MM-DD"),
				end_date: moment(periodDate)
					.add(2, "years")
					.format("YYYY-MM-DD"),
				dairy_id: dairy.id
			})
		});
	},
	afterModel(model) {
		model.months = model.months.sortBy("date");

		let month;
		let instrument;
		for (let i = 0; i < model.months.length; i++) {
			month = model.months[i];
			instrument = model.cashSettledCheeseFutures.find(e => {
				return moment(e.get("display_expiration_date")).isSame(month.date, "day");
			});
			month.set("cashSettledCheeseInstrument", instrument);
		}

		model.selectableMonths = model.months.map(m => {
			return EmberObject.create({
				month: m,
				checked: false
			});
		});
	}
});
