import Controller from "@ember/controller";
import PeriodMixin from "agropur-client/mixins/period";
import moment from "moment";
import CSVExportMixin from "agropur-client/mixins/csv-export";
import { computed } from "@ember/object";
import ENV from "agropur-client/config/environment";

export default Controller.extend(PeriodMixin, CSVExportMixin, {
	ENV,
	showClassThreeTrades: true,
	showBasisTrades: true,
	filteredTrades: computed(
		"model.sortedTrades",
		"showClassThreeTrades",
		"showBasisTrades",
		function() {
			return this.model.sortedTrades.filter(t => {
				return t.productId == "CSC";
			});
		}
	),
	actions: {
		toggleClassThreeTrades() {
			this.set("showClassThreeTrades", !this.showClassThreeTrades);
		},
		toggleBasisTrades() {
			this.set("showBasisTrades", !this.showBasisTrades);
		},
		csvExport() {
			const data = {
				filename: null,
				rows: []
			};

			const header = [
				"Trade Date",
				"Producer Number",
				"Producer",
				"User ID",
				"Contract Month",
				"Product",
				"Bought/Sold",
				"Contracted(lbs)",
				"Type",
				"Strike",
				"Trade Price",
				"Ticket ID",
				"Broker"
			];
			data.rows.push(header);

			this.get("filteredTrades").forEach(t => {
				const timestamp = moment.utc(t.get("timestamp")).format("MM/DD/YYYY");
				const producerId = t.get("producerId");
				const producer = t.get("producerName").replace(",", " ");
				const userId = t.get("userId");
				const expiration = moment.utc(t.get("expiration")).format("MMM YYYY");
				const productId =
					t.get("productId") === "DC" ? "Class III Milk" : "Agropur Basis";
				const isLong = t.get("isLong") ? "Bought" : "Sold";
				const quantity = t.get("quantity");
				const type = t.get("type") === "FORWARD" ? "FORWARD" : t.get("type");
				const strike = t.get("strike") || "";
				const price = t.get("price");
				const ticketId = t.get("instrumentId");
				const broker = t.get("broker");

				const tradeRow = [
					timestamp,
					producerId,
					producer,
					userId,
					expiration,
					productId,
					isLong,
					quantity.toFixed(0),
					type,
					strike,
					price.toFixed(2),
					ticketId,
					broker
				];
				data.rows.push(tradeRow);
			});

			data.filename = this.get("model.organization.name")
				.split(" ")
				.join("_");
			return this.downloadCSVFile(data, "Trade Log", this.model.periodDate);
		}
	}
});
