import config from "./config/environment";
import EmberRouter from "@ember/routing/router";

const Router = EmberRouter.extend({
	location: config.locationType,
	rootURL: config.rootURL
});

Router.map(function() {
	this.route("login");

	this.route("authenticated", { path: "/" }, function() {
		this.route("dashboard");
		this.route("milk-production");
		this.route("dairy-contacts");
		this.route("dairy-settings");
		this.route("dairy-notes");
		this.route("forward-prices");

		// REPORTS
		this.route("milk-hedges");
		this.route("milk-physical-position-statement");
		this.route("milk-revenue-report");

		this.route("basis-prices", function() {
			this.route("detail", { path: "/:symbol" });
		});

		this.route("class-iii-hedges", function() {
			this.route("detail", { path: "/:id" });
		});

		this.route("cheese-hedges", function() {
			this.route("detail", { path: "/:id" });
		});

		this.route("basis-hedges", function() {
			this.route("detail", { path: "/:id" });
		});

		this.route("organization-members", function() {
			this.route("member", { path: "/:id" });
		});

		this.route("organization-dairies");
		this.route("organization-settings");
		this.route("organization-aggregate", function() {
			this.route("class-iii-and-basis-pl");
			this.route("percentage-hedged");
			this.route("production-report");
			this.route("trade-log");
			this.route("weighted-avg-basis-price");
		});

		this.route("organization", function() {
			this.route("member", { path: "/members/:member_id" });
		});

		this.route("markets");

		this.route("dairy", { path: "/dairies/:id" }, function() {
			this.route("notes");

			this.route("settings", function() {
				this.route("profile");
			});
		});
	});
});

export default Router;
