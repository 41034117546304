import Controller from "@ember/controller";
import ENV from "agropur-client/config/environment";
export default Controller.extend({
	ENV,
	init() {
		this._super(...arguments);
		this.set("productionTypes", ["POUNDS_COW_DAY", "TOTAL"]);

		this.set("defaultUserDairyConfigPermissions", ["DAIRY_READ", "DAIRY_WRITE"]);
	},

	actions: {
		rollbackDairy(dairy) {
			dairy.rollbackAttributes();
		},

		saveDairy(dairy) {
			dairy.save();
		},

		sendClickWrap() {
			this.send("showClickWrap");
		}
	}
});
