import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import Route from "@ember/routing/route";
import { hash } from "rsvp";
import moment from "moment";

export default Route.extend(AuthenticatedRouteMixin, {
	model() {
		const { periodDate, dairy, organization } = this.modelFor("authenticated");

		return hash({
			periodDate,
			organization,
			months: this.get("store").query("month", {
				start_date: periodDate.format("YYYY-MM-DD"),
				end_date: moment(periodDate)
					.add(23, "months")
					.format("YYYY-MM-DD"),
				dairy_id: dairy.id
			}),
			manualPositions: this.get("store").query("manual-position", {
				dairy_id: dairy.id,
				product_id: "CSC"
			})
		});
	},
	afterModel(model) {
		model.manualPositions = model.manualPositions.filter(m => {
			return moment(m.get("month_id.date")).isSameOrAfter(
				moment().subtract(1, "month")
			);
		});
	}
});
