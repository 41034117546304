import AuthenticatedRouteMixin from "ember-simple-auth/mixins/authenticated-route-mixin";
import Route from "@ember/routing/route";
import { hash } from "rsvp";
import moment from "moment";

export default Route.extend(AuthenticatedRouteMixin, {
	model(params) {
		const {
			dairy,
			cashSettledCheeseFutures,
			currentUserId,
			isOrgAdmin
		} = this.modelFor("authenticated");
		return hash({
			manualPositions: this.get("store").query("manual-position", {
				dairy_id: dairy.id,
				product_id: "CSC"
			}),
			month: this.get("store").findRecord("month", params.id),
			cashSettledCheeseFutures,
			currentUserId,
			isOrgAdmin
		});
	},
	afterModel(model) {
		let instrument = model.cashSettledCheeseFutures.find(e => {
			return moment(e.get("display_expiration_date")).isSame(
				model.month.date,
				"day"
			);
		});

		model.month.set("cashSettledCheeseInstrument", instrument);
	},
	actions: {
		refreshModel() {
			this.refresh();
		}
	}
});
