import Controller from "@ember/controller";
import UnauthenticatedRouteMixin from "ember-simple-auth/mixins/unauthenticated-route-mixin";
import ENV from "agropur-client/config/environment";

export default Controller.extend(UnauthenticatedRouteMixin, {
	routeAfterAuthentication: "authenticated.dashboard",
	statusMessage: null,
	errorMessage: null,
	brandLogoUrl: null,
	brandLogoText: null,
	ENV,

	init() {
		this._super(...arguments);
		this.set("brandLogoUrl", ENV.BRAND.logoPath);
		this.set("brandLogoText", ENV.BRAND.logoAltText);
	},

	setStatus(message) {
		this.set("statusMessage", message);
		this.set("errorMessage", null);
	},

	setError(message) {
		this.set("statusMessage", null);
		this.set("errorMessage", message);
	},
	actions: {
		async authenticate() {
			this.setStatus("Authenticating...");
			var identification = this.get("identification").trim();
			var password = this.get("password").trim();
			this.get("session")
				.authenticate("authenticator:oauth2", identification, password, null, {
					Authorization: ENV.APP.CLIENT_OAUTH,
					Accept: "application/vnd.optionscity.v2+json"
				})
				.then(() => {
					this.set("statusMessage", null);
					this.set("errorMessage", null);
				})
				.catch(err => {
					this.setError(err);
				});
		}
	}
});
