import ApplicationRouteMixin from "ember-simple-auth/mixins/application-route-mixin";
import Route from "@ember/routing/route";
import { inject as service } from "@ember/service";
import ENV from "agropur-client/config/environment";

export default Route.extend(ApplicationRouteMixin, {
	headData: service(),
	routeAfterAuthentication: "authenticated.dashboard",

	afterModel: function() {
		this.headData.set("title", ENV.BRAND.title);
		this.headData.set("showMobileConfig", ENV.BRAND.showMobileConfig);
	},

	actions: {
		invalidateSession() {
			this.get("session").invalidate();
		}
	}
});
