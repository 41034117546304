import { computed } from "@ember/object";
import Controller from "@ember/controller";
import DateUtils from "agropur-client/utils/date-utils";
const { dateInput, toUtc, format } = DateUtils;
import ENV from "agropur-client/config/environment";

export default Controller.extend({
	ENV,
	updatedBasisPrice: null,

	checkedInstruments: computed.filterBy(
		"model.selectableInstruments",
		"checked",
		true
	),

	partialInstrumentsSelected: computed(
		"checkedInstruments.@each.checked",
		function() {
			return (
				this.checkedInstruments.length > 0 &&
				this.checkedInstruments.length < this.model.selectableInstruments.length
			);
		}
	),

	allInstrumentsSelected: computed(
		"checkedInstruments.@each.checked",
		function() {
			return (
				this.checkedInstruments.length == this.model.selectableInstruments.length
			);
		}
	),

	actions: {
		saveRecords() {
			const list = this.get("checkedInstruments");
			const price = this.get("updatedBasisPrice");

			list.forEach(el => {
				const instrument = el.get("instrument");
				const instrumentSymbol = el.get("instrument.symbol");
				const timestamp = format(toUtc(new Date()), "YYYY-MM-DD HH:mm:ss");
				const closeDate = dateInput(toUtc(el.get("instrument.expiration")));

				const model = this.get("store").createRecord("vault-market-datum", {
					instrument,
					instrumentSymbol,
					timestamp,
					closeDate,
					price,
					side: "Last",
					final: false
				});
				model.save();
			});

			this.toggleProperty("showBasisOfferModal");
			this.set("updatedBasisPrice", null);
		},

		toggleBasisOfferModal() {
			this.set("updatedBasisPrice", null);
			this.toggleProperty("showBasisOfferModal");
		},

		cancelBasisOfferModal() {
			this.toggleProperty("showBasisOfferModal");
			this.set("updatedBasisPrice", null);
		},

		updateAllSelectedInstruments() {
			if (this.checkedInstruments.length > 0 && !this.partialInstrumentsSelected) {
				this.checkedInstruments.forEach(m => {
					m.set("checked", false);
				});
			} else {
				this.model.selectableInstruments.forEach(m => {
					m.set("checked", true);
				});
			}
		}
	}
});
